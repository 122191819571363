
.detail-container {
    padding: 20px;
    background-color: #f2f4f7;
    height: 100%;

    .good-selection-box {
        font-size: 18px;
        color: #000000;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .good-img {
            border-radius: 5px;
        }
    }

    .card-box {
        display: flex;
        flex-wrap: wrap;
        margin-left: -28px;

        .card-item {
            flex: 1;
            background: #ffffff;
            margin: 0 0 28px 28px;
            border: 1px solid #EEEFF0;
            border-radius: 4px;
            padding: 20px 24px;

            .card-title {
                font-size: 18px;
                margin-bottom: 30px;
            }

            .card-list {
                display: flex;
                justify-content: space-around;

                .card-line {
                    .title {
                        white-space: nowrap;
                    }

                    .iconfont {
                        color: #409eff;
                        margin-right: 4px;
                    }

                    .text {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .data-box {
        background: #ffffff;
        border: 1px solid #EEEFF0;
        border-radius: 4px;
        padding: 20px 24px;

        &+.data-box {
            margin-top: 28px;
        }

        .data-title {
            margin-bottom: 28px;
            font-size: 18px;
        }
    }
}
